progress.progress-bar {
  border-radius: 2px;
  display: block;
  height: 4px;
  margin-top: 5px;
  width: 239px;
}

progress.progress-bar::-webkit-progress-bar {
  background-color: #d8deec;
  border-radius: 2px;
}

progress.progress-bar::-webkit-progress-value {
  background-color: var(--bg-black-color);
  background-image: var(--bg-black-color);
  border-radius: 2px;
}

progress.progress-bar::-moz-progress-bar {
  background-color: var(--bg-black-color);
  background-image: var(--bg-black-color);
  border-radius: 2px;
}
