.snack-bar-errors-messages {
  margin: 0 !important;
  position: absolute;
  right: 25px;
  top: 52px;
  background-color: #606061;

  &.mat-snack-bar-container {
    min-height: 34px;
    min-width: unset;
    padding: 5px 10px 5px 16px;
    border-radius: 12px;
  }

  .mat-simple-snackbar-action {
    margin: 0;
  }

  button {
    margin-left: 5px;
    font-size: 0;
    position: relative;
    width: 24px;
    height: 24px;
    padding: 0;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 30%;
      z-index: 1;
      width: 11px;
      height: 1.5px;
      background-color: white;
      border-radius: 5px;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }
}
