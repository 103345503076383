@import './assets/styles/custom';
@import './assets/styles/constants';
@import './assets/styles/kp-svg';
@import './assets/styles/blocks/circle-img';
@import './assets/styles/blocks/buttons-container';
@import './assets/styles/blocks/button';
@import './assets/styles/blocks/page-container';
@import './assets/styles/blocks/progress-bar';
@import './assets/styles/blocks/file-view';
@import './assets/styles/blocks/default-input';
@import './assets/styles/blocks/form-control';
@import './assets/styles/blocks/link';
@import './assets/styles/blocks/tile';
@import './assets/styles/blocks/actions-bar';
@import './assets/styles/blocks/title';
@import './assets/styles/blocks/table';
@import './assets/styles/blocks/primary-info';
@import './assets/styles/blocks/primary-text';
@import './assets/styles/blocks/default-container';
@import './assets/styles/blocks/name-value-grid';
@import './assets/styles/blocks/secondary-text';
@import './assets/styles/blocks/tooltip';
@import './assets/styles/customs/mat-checkbox';
@import './assets/styles/customs/mat-slide-toggle';
@import './assets/styles/customs/mat-dialog';
@import 'assets/styles/customs/mat-form-field';
@import './assets/styles/customs/mat-menu';
@import './assets/styles/customs/mat-radio';
@import './assets/styles/customs/mat-tree';
@import './assets/styles/customs/scrollbar';
@import './assets/styles/customs/quill';
@import './assets/styles/customs/menu-backdrops';
@import './assets/styles/customs/mat-calendar';
@import '~@ng-select/ng-select/themes/default.theme.css';
@import '../node_modules/angular-calendar/css/angular-calendar.css';
@import '~@angular/cdk/overlay-prebuilt.css';
@import './assets/styles/customs/modal-backdrops.scss';

@import './assets/styles/ui-kit/ui-kit.scss';
@import 'material-icons/iconfont/material-icons.scss';
@import './assets/styles/booking-modal-custom.scss';
@import './assets/styles/customs/snack-bar.scss';

:root {
  --red-color: #ca3332;
  --white-color: #fff;
  --lavender-color: #f1f1f1;
  --darkgray-color: #a8aeba;
  --black-active-color: #171619;
  --lightslategray-color: #8b8f97;

  // In .scss files use only this variables. Don't use variables above this comment.
  --bg-black-color: #171619;
  --bg-darkgray-color: var(--darkgray-color);
  --bg-gray-color: #eaeef2;
  --bg-yellow-color: #ffcc6a;
  --bg-light-color: #f7f9fb;
  --bg-white-color: var(--white-color);
  --bg-gainsboro: #d0d6e6;
  --bg-thin-graphic-color: var(--lavender-color);
  --bg-inactive-color: var(--lightslategray-color);

  --accent-color: #58a0b7;
  --secondary-color: #6a728a;

  --text-white-color: var(--white-color);
  --text-black-color: var(--black-active-color);
  --text-red-color: var(--red-color);
  --inactive-text-color: var(--darkgray-color);

  --icon-white-color: var(--white-color);
  --icon-black-color: var(--black-active-color);
  --icon-red-color: var(--red-color);
  --inactive-icon-color: var(--lightslategray-color);

  --thin-graphic-color: var(--lavender-color);
  --gray-border-color: var(--darkgray-color);
  --red-border-color: rgba(244, 165, 164, 0.8);

  --sidebar-width: 276px;
  --search-bar-height: 48px;
  --scrollbar-width: 8px;
  --scrollbar-width-inverted: -8px;

  --sidebar-menu-width: 131px;
  --chat-right-padding: 63px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'HelveticaNeueNew', sans-serif;

  &:focus {
    outline: none;
  }
}

html,
body {
  height: 100%;
  width: 100%;
  overflow: auto;
}

a {
  text-decoration: none;
  color: unset;
}

input::placeholder,
textarea::placeholder {
  color: var(--inactive-text-color);
}

textarea {
  resize: none;
}

.invisible {
  display: none;
  position: fixed;
  z-index: -1;
  height: 0;
  width: 0;
  overflow: hidden;
}

.snack-bar-container-custom button {
  color: white;
}
