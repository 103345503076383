.title {
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  color: var(--text-black-color);

  &--lg {
    font-size: 28px;
    line-height: 28px;
  }
}
