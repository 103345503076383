.small-chip {
  height: 22px;
  font-size: 11px;
  line-height: 12px;
  border-radius: 22px;
  color: #FFFFFF;
  background-color: #15151599;
  padding: 5px 8px;
  width: fit-content;
}
