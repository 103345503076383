$primary: #5b2f87;
$primary-hover: #451E6C;
$primary-active: #301150;
$primary-disabled: #D3D2DD;

$secondary: #797E8C;

$primary-text: #171619;
$light-text: #797E8C;
$extra-light-text: #B3B4BE;

$neutral-disabled: #F2F5F7;

// For Opora
//disabled color for switcher #969fc2;

// For Ladies
//$primary-disabled: #D3D2DD;

// For KP
//$primary-disabled: #ad96c2;

$tertiary: #A1A4AD;

$border: #D8E0E7;
$border-hovered: #C0C7CE;
$border-light: #EBF1F6;
$border-extra-light: #F0F1F2;

$white: #ffffff;

$error: #FF3B30;

$accent: #AAD2FD;
$accent-active: #4985dc;
$accent-disabled: #ebf5ff;

// opora border-radius: 200px;
$border-radius: 8px;

// opora color #0053B2
$profile-edit-icon: #A18AB6;

//booking types
$booked: #ACD4D0;
$booked-other-organization: #F8D9BE;
$booked-meet: #C9CAF0;
$booked-color-text: #171619;
