@import 'src/assets/styles/ui-kit/common-colors';


.more-about-meet {
  color: $primary;
  font-size: 16px;
  font-weight: 400;
  margin-top: 13px;
  padding-top: 10px;
  text-align: end;
}

.notification-circle-year-calendar {
  background: $primary;
  border-radius: 50%;
  height: 5px;
  left: 30px;
  position: absolute;
  top: 10px;
  width: 5px;
}
