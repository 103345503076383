.overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.flex {
  display: flex;
}

.flex-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.align-start {
  align-items: flex-start;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.justify-center {
  justify-content: center;
}

.space-between {
  justify-content: space-between;
}

.justify-between {
  justify-content: space-between;
}

.column-center {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.text-align-center {
  text-align: center;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.white-space-pre {
  white-space: pre;
}

.cursor-pointer {
  cursor: pointer;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.half-width {
  width: 50%;
}

.width-max-content {
  width: max-content;
}

.block {
  display: block !important;
}

.inline-block {
  display: inline-block;
}

.display-none {
  display: none;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
  text-decoration: none;
  color: gray;
}

.user-select--disabled {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.underline-hover {
  &:hover {
    text-decoration: underline;
  }
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}


