.primary-info-container {
  display: flex;
  flex-direction: column;
  overflow: auto;

  .primary-info:not(:first-child) {
    margin-top: 4px;
  }
}

.primary-info {
  --bg-color: #fff;
  --accent-color: #000;

  border-radius: 2px;
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  padding: 5px;
  border-left: var(--accent-color) 3px solid;
  background-color: var(--bg-color);

  > * {
    &:not(:first-child) {
      margin-top: 3px;
    }
  }

  &--gray {
    --bg-color: rgba(216, 222, 236, 0.45);
    --accent-color: #7eabd5;
  }

  &--red {
    --bg-color: rgba(245, 168, 168, 0.3);
    --accent-color: #f5a8a8;
  }

  &--green {
    --bg-color: rgba(64, 164, 177, 0.3);
    --accent-color: #40a4b1;
  }

  &__accent-text {
    color: var(--accent-color);
    font-size: 10px;
    font-weight: 500;
  }

  &__title-text {
    color: var(--text-black-color);
    font-size: 12px;
    font-weight: 500;
  }

  &__additional-text {
    color: var(--text-black-color);
    font-size: 10px;
    font-weight: 300;
  }

  &--link {
    width: fit-content;

    &:hover {
      text-decoration: underline;
    }
  }
}
