@import 'src/assets/styles/ui-kit/common-colors';

.slide-toggle {
  .mat-slide-toggle-bar {
    margin: 0;
    order: 2;
    height: 21px;
    width: 37px;
    border-radius: 30px;
    background-color: var(--bg-darkgray-color);
  }

  .mat-slide-toggle-label {
    justify-content: space-between;
  }

  &.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
    background-color: var(--bg-white-color);
  }

  &.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
    background-color: $primary;
  }

  &.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: $primary;
  }

  &.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: var(--bg-white-color);
  }

  .mat-slide-toggle-thumb-container {
    width: 15px;
    height: 15px;
    top: 3px;
    left: 3px;
  }

  .mat-slide-toggle-thumb {
    height: 15px;
    width: 15px;
  }

  .mat-slide-toggle-content {
    order: 1;
    font-weight: 500;
    font-size: 15px;
    font-family: 'Styrene A Web', sans-serif;
    line-height: 18px;
    color: var(--text-black-color);
  }
}
