@import "common-colors";

@mixin kp-checkbox {
  .mat-checkbox-inner-container {
    display: inline-flex;
    height: 20px;
    width: 20px;
    border-radius: 3px;
    border: 1px solid $border;
  }

  .mat-checkbox-frame {
    border-style: hidden;
    border-width: 1px;
  }

  .mat-checkbox-inner-container .mat-checkbox-ripple {
    position: fixed;
    left: inherit;
    top: inherit;
    transform: translate(-12px, -12px);
  }
}

.kp-checkbox {
  @include kp-checkbox;
}


.kp-checkbox-s {
  @include kp-checkbox;
  font-size: 13px;
}

.kp-checkbox-m {
  @include kp-checkbox;
  font-size: 14px;
}

.kp-checkbox-without-ripple {
  @include kp-checkbox;

  .mat-checkbox-inner-container .mat-checkbox-ripple {
    display: none;
  }
}

.kp-checkbox-tertiary {
  .mat-checkbox-inner-container {
    border-color: $tertiary !important;
  }
}

.kp-tertiary-checkbox-without-ripple {
  @extend .kp-checkbox-without-ripple;
  @extend .kp-checkbox-tertiary;
}
