.file {
  align-items: center;
  display: flex;
  position: relative;
  width: 100%;

  &__text-icon {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 52px;
    height: 39px;
    background: #fff;
    border-radius: 3px;
  }

  &__image-preview {
    width: 52px;
    height: 39px;
  }

  &__delete-icon {
    color: var(--inactive-icon-color);
    left: 490px;
    position: absolute;
    top: 9px;
  }
}

.file-progress {
  display: flex;
  flex-direction: column;
  padding-left: 19px;
  max-width: 435px;
  overflow: hidden;

  &__custom-upload {
    display: flex;
    font-size: 14px;
    line-height: 18px;

    color: #171619;

    span {
      font-weight: 300;
      font-size: 11px;
      line-height: 14px;
      letter-spacing: 0.02em;
      text-transform: capitalize;
      padding-left: 13px;
      padding-top: 4px;
      color: var(--secondary-color);
    }
  }
}
