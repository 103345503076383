.mat-tree {
  padding: 10px 0;
  overflow: hidden;
}

.mat-tree-node {
  min-height: 36px;

  .mat-checkbox {
    width: 100%;
  }

  .mat-checkbox-layout {
    max-width: 100%;
    width: fit-content;
  }

  .mat-checkbox-label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 18px;
  }
}
