@import "common-colors";

@mixin kp-input() {
  border: none;
  font-size: 13px;
  flex: 1
}

@mixin kp-form-control {
  padding: 7px 12px 7px 12px;
  border: 1px solid #D8E0E7;
  border-radius: 8px;
  background: $white;
  font-size: 13px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 7px;
}

@mixin kp-form-control-l {
  @include kp-form-control();
  height: 52px;
  min-height: 52px;
}

@mixin kp-form-control-s {
  @include kp-form-control();
  height: 32px;
  min-height: 32px;
}

.kp-form-control-l {
  @include kp-form-control-l();

  input {
    @include kp-input();
  }
}

.kp-form-control-s {
  @include kp-form-control-s();

  input {
    @include kp-input();
  }
}
