.form-control {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: max-content;
  width: 100%;

  &--direction-row {
    flex-direction: row;
    align-items: flex-start;
  }

  &--align-center {
    align-items: center;
  }

  &--full-height {
    height: 100%;
  }

  &__input-line-container {
    display: flex;
    width: inherit;
  }

  .form-control__input-field,
  input,
  textarea {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    border: none;
    padding: 8px 8px 8px 12px;
    height: inherit;
    width: inherit;
    font-weight: 300;
    font-size: 14px;
    background-color: transparent;
    color: var(--text-black-color);
  }

  &__input {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: inherit;
    @extend .default-input;

    &--full-height {
      height: 100%;
    }

    &--direction-start {
      align-items: flex-start;
      justify-content: start;
    }

    &--align-center {
      align-items: center;
    }

    &--border-transparent {
      border-color: transparent;
    }

    &:focus-within {
      border-color: var(--inactive-text-color);
    }

    &--text-select-style {
      input {
        font-weight: 500;
        font-size: 12px;
        color: var(--secondary-color);
      }
    }

    kp-select,
    kp-date-picker {
      width: 100%;
    }

    kp-date-picker {
      .dp-bar {
        border: none;
      }
    }

    > kp-svg {
      margin-right: 8px;
    }
  }

  &__input-hint {
    margin-left: 18px;
    font-size: 11px;
    font-weight: 300;
  }

  &--invalid {
    .form-control__input {
      border-color: var(--red-border-color);
    }
  }

  &--required {
    .form-control__input {
      &::after {
        content: '*';
        position: absolute;
        display: flex;
        align-items: center;
        height: 100%;
        font-size: 18px;
        left: calc(100% + 6px);
      }
    }
  }

  &--height-unset {
    .form-control__input {
      height: unset;
    }
  }

  &__error {
    position: absolute;
    left: 0;
    top: 100%;
    font-weight: 300;
    font-size: 11px;
    color: var(--text-red-color);
    letter-spacing: 0.02em;
    width: 100%;

    &--in-flow {
      position: static;
    }

    &--black {
      color: var(--text-black-color);
    }
  }

  .quill-editor {
    @extend .quill-editor--no-border;
  }
}
