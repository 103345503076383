.page-container {
  height: 100%;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
  padding: 30px 0px 30px 23px;
  position: relative;
  overflow-y: scroll;
  overflow-x: auto;

  &--no-paddings {
    padding: 0;
  }

  &--no-x-paddings {
    padding: 30px 0;
  }

  &--no-y-paddings {
    padding: 0 23px;
  }

  &--no-overflow {
    overflow: hidden;
  }
}
