.tile {
  display: flex;
  flex-direction: column;
  background: var(--bg-white-color);
  border: 1px solid var(--thin-graphic-color);
  border-radius: 1px;
  width: 323px;

  > div {
    width: 100%;
  }

  &__main-block {
    display: flex;
    flex-direction: column;
    padding: 12px;
  }

  &__additional-block {
    display: flex;
    flex-direction: column;
    border-top: 1px solid var(--thin-graphic-color);
    padding: 12px;

    > div {
      display: flex;
      font-weight: 300;
      font-size: 12px;

      &:not(:first-of-type) {
        margin-top: 15px;
      }

      kp-svg {
        color: var(--secondary-color);
        margin-top: 4px;
        margin-right: 15px;
      }
    }
  }
}

.tile-actions {
  display: flex;

  kp-select {
    margin-left: auto;
  }
}

.tile-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--text-black-color);

  > div {
    text-align: center;
  }

  &__title {
    @extend .title;
    margin-top: 13px;
    text-align: center;
  }

  &__default-text {
    font-weight: 300;
    font-size: 12px;
    margin-top: 8px;

    &--underline {
      a:hover {
        text-decoration: underline;
      }
    }
  }

  &__primary-text-container,
  &__primary-text {
    margin-top: 11px;
    margin-bottom: 9px;
  }
}
