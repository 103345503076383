.tooltip-container {
  position: fixed;
  z-index: 2;
  cursor: pointer;

  &--cursor-default {
    cursor: default;
  }

  &__content {
    --arrow-height: 8px;
    --arrow-half-width: 6px;

    position: relative;
    background: var(--bg-black-color);
    border-radius: 4px;
    color: var(--text-white-color);
    font-size: 12px;
    line-height: normal;
    padding: 6px 8px;
    max-width: 200px;
    font-weight: 300;
    user-select: none;

    &::after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      bottom: 100%;
      left: calc(50% - var(--arrow-half-width));
    }

    &--arrow-top {
      margin-top: var(--arrow-height);

      &::after {
        border-width: 0 var(--arrow-half-width) var(--arrow-height) var(--arrow-half-width);
        border-color: transparent transparent var(--bg-black-color) transparent;
      }
    }

    &--arrow-bottom {
      margin-bottom: var(--arrow-height);

      &::after {
        border-width: var(--arrow-height) var(--arrow-half-width) 0 var(--arrow-half-width);
        border-color: var(--bg-black-color) transparent transparent transparent;
      }
    }
  }
}
