.name-value-grid {
  display: grid;
  grid-template-columns: 170px auto;
  grid-column-gap: 5px;
  grid-row-gap: 22px;

  &__name {
    display: flex;
    @extend .secondary-text, .secondary-text--bold, .secondary-text--sm;

    &--pt-half-line {
      padding-top: calc(1rem / 2);
    }
  }

  &__value {
    display: flex;
    font-size: 14px;
    line-height: 15px;
    font-weight: 300;
    word-break: break-word;

    &--sm {
      font-size: 12px;
    }

    &--normal {
      font-weight: 400;
    }

    &--bold {
      font-weight: 500;
    }

    &--max-content {
      width: max-content;
    }

    &--half-content {
      width: 50%;
    }

    &--link {
      align-items: center;
      cursor: pointer;
      max-width: fit-content;

      kp-svg {
        margin-left: 4px;
        color: var(--accent-color);
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__values-in-column {
    @extend .name-value-grid__value;
    flex-direction: column;

    .name-value-grid__value {
      &:not(:last-of-type) {
        margin-bottom: 5px;
      }
    }
  }

  &__values-in-row {
    @extend .name-value-grid__value;
    flex-wrap: wrap;
    gap: 8px;

    .name-value-grid__value {
      display: inline-flex;
    }

    &--nowrap {
      flex-wrap: nowrap;
    }

    &--gap-lg {
      gap: 20px;
    }
  }
}
