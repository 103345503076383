* {
  scrollbar-width: thin;
  scrollbar-color: var(--bg-gainsboro) transparent;
}

::-webkit-scrollbar {
  height: var(--scrollbar-width);
  width: var(--scrollbar-width);
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--bg-gainsboro);
  border-radius: var(--scrollbar-width);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--secondary-color);
}
