@import "../ui-kit/common-colors";

//1rem = 16px

.mat-form-field {
  $font-size: 0.875rem;
  font-size: $font-size !important;

  input {
    font-size: $font-size;
  }

  label.mat-form-field-label {
    font-size: 1rem;
    top: 1.55em;
  }

  .mat-form-field-subscript-wrapper {
    margin-top: 2px !important;
  }

  &.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  &.mat-form-field-can-float
  .mat-input-server:focus
  + .mat-form-field-label-wrapper
  .mat-form-field-label {
    transform: translateY(-1.25em) scale(0.75);
    width: 133.33333%;
  }
}

.mat-form-field-appearance-outline .mat-form-field-flex {
  padding: 0 1em !important;

  .mat-form-field-outline-start {
    border-radius: 8px 0 0 8px;
    min-width: 8px;
  }

  .mat-form-field-outline-end {
    border-radius: 0 8px 8px 0;
  }

  .mat-form-field-infix {
    padding: 0.7rem 0 1.1rem 0;
  }
}

// Можно использовать, чтобы убрать стандартный паддинг снизу,
// если у контрола не планируется вывода подсказок или ошибок
.no-padding {
  .mat-form-field-wrapper {
    padding: 0 !important;
  }
}

/*
 Высота для mat-form-field по умолчанию - 52px
 ниже будут классы-модификаторы
 -s - 40px
 -m - по-умолчанию
 -l - undefined
 */

.mat-form-field-s {
  .mat-form-field-infix {
    padding: 0.53rem 0 !important;
  }

  label.mat-form-field-label {
    top: 1.15rem;
  }

  .mat-form-field-infix {
    padding: 0.4rem 0 0.6rem 0 !important;
  }

  &.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  &.mat-form-field-can-float
  .mat-input-server:focus
  + .mat-form-field-label-wrapper
  .mat-form-field-label {
    transform: translateY(-0.99rem) scale(0.75);
    width: 133.33333%;
  }
}

.mat-select-search-no-entries-found {
  text-align: center;
}

.mat-select-without-arrow {
  .mat-select-arrow {
    visibility: hidden !important;
  }
}

// Mat textarea

@mixin mat-textarea-border {

  .mat-form-field-outline-start {
    border-radius: 12px 0 0 12px !important;
    min-width: 12px !important;
  }

  .mat-form-field-outline-end {
    border-radius: 0 12px 12px 0 !important;
  }
}

.mat-textarea-s {
  @include mat-textarea-border();

  .mat-form-field-infix {
    padding: 5px 0 13px 0 !important
  }
}

// не подсвечивать цветом при выборе формы
.no-focus {
  .mat-form-field-outline {
    color: $border !important;
    border-width: 1px !important;
  }

  .mat-form-field-outline-start, .mat-form-field-outline-end {
    border-width: 1px !important;
  }
}

.no-scroll {
  textarea {
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0;
    }
  }
}
