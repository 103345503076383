@import "common-colors";

@mixin callout {
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid;
}

@mixin s {
  border-radius: 12px;
  font-size: 13px;
  padding: 8px;
}

@mixin info {
  color: $accent-active;
  border-color: $accent;
  background-color: $accent-disabled;

  kp-svg {
    color: $accent-active;
  }
}

.callout-info-s {
  @include callout;
  @include info;
  @include s;
}
