/* Normal */
@font-face {
  font-family: 'HelveticaNeueNew';
  src: url('././HelveticaNeue/UltraLight-06/HelveticaNeue-UltraLight-06.ttf') format('truetype'),
  url('././HelveticaNeue/UltraLight-06/HelveticaNeue-UltraLight-06.eot') format('embedded-opentype'),
  url('././HelveticaNeue/UltraLight-06/HelveticaNeue-UltraLight-06.otf') format('opentype'),
  url('././HelveticaNeue/UltraLight-06/HelveticaNeue-UltraLight-06.woff') format('woff'),
  url('././HelveticaNeue/UltraLight-06/HelveticaNeue-UltraLight-06.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueNew';
  src: url('././HelveticaNeue/Thin-13/HelveticaNeue-Thin-13.ttf') format('truetype'),
  url('././HelveticaNeue/Thin-13/HelveticaNeue-Thin-13.eot') format('embedded-opentype'),
  url('././HelveticaNeue/Thin-13/HelveticaNeue-Thin-13.otf') format('opentype'),
  url('././HelveticaNeue/Thin-13/HelveticaNeue-Thin-13.woff') format('woff'),
  url('././HelveticaNeue/Thin-13/HelveticaNeue-Thin-13.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
}

/* @font-face {
  font-family: 'HelveticaNeueNew';
  src: url('././HelveticaNeue/HelveticaNeue-Light-08.ttf');
  font-weight: 300;
  font-style: normal;
} */

@font-face {
  font-family: 'HelveticaNeueNew';
  src: url('././HelveticaNeue/HelveticaNeueCyr-Light300.ttf');
  font-weight: 400;
  font-style: normal;
  ascent-override: 100%;
}

@font-face {
  font-family: 'HelveticaNeueNew';
  src: url('././HelveticaNeue/HelveticaNeueCyr-Light300.ttf');
  font-weight: normal;
  font-style: normal;
  ascent-override: 100%;
}

@font-face {
  font-family: 'HelveticaNeueNew';
  src: url('././HelveticaNeue/HelveticaNeueCyr-Roman400.ttf');
  font-weight: 500;
  font-style: normal;
  ascent-override: 100%;
}

@font-face {
  font-family: 'HelveticaNeueNew';
  src: url('././HelveticaNeue/HelveticaNeueCyr-Medium500.ttf');
  font-weight: 700;
  font-style: normal;
  ascent-override: 100%;
}

/* Italic */
@font-face {
  font-family: 'HelveticaNeueNew';
  src: url('././HelveticaNeueItalic/HelveticaNeue-UltraLightItalic-07.ttf');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'HelveticaNeueNew';
  src: url('././HelveticaNeueItalic/HelveticaNeue-ThinItalic-14.ttf');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'HelveticaNeueNew';
  src: url('././HelveticaNeueItalic/HelveticaNeue-LightItalic-09.ttf');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'HelveticaNeueNew';
  src: url('././HelveticaNeueItalic/HelveticaNeue-Italic-03.ttf');
  font-weight: 400;
  font-style: italic;
  ascent-override: 100%;
}

@font-face {
  font-family: 'HelveticaNeueNew';
  src: url('././HelveticaNeueItalic/HelveticaNeue-MediumItalic-12.ttf');
  font-weight: 500;
  font-style: italic;
  ascent-override: 100%;
}

@font-face {
  font-family: 'HelveticaNeueNew';
  src: url('././HelveticaNeueItalic/HelveticaNeue-BoldItalic-04.ttf');
  font-weight: 700;
  font-style: italic;
  ascent-override: 100%;
}

/* Emoji */
@font-face {
  font-family: 'NotoColorEmojiLimited';
  unicode-range: U+1F1E6-1F1FF;
  src: url('./NotoColorEmoji.ttf') format('truetype');
}
