.circle-img {
  object-fit: cover;
  object-position: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;

  &--sm {
    height: 26px;
    width: 26px;
  }

  &--premd {
    height: 36px;
    width: 36px;
  }

  &--mini-md {
    height: 32px;
    width: 32px;
  }

  &--md {
    height: 38px;
    width: 38px;
  }

  &--md-semi-plus {
    height: 46px;
    width: 46px;
  }

  &--md-plus {
    height: 48px;
    width: 48px;
  }

  &--lg {
    height: 63px;
    width: 63px;
  }

  &--xl {
    height: 83px;
    width: 83px;
  }

  &--80 {
    height: 80px;
    width: 80px;
  }
}
