.radio-group {
  --buttons-size: 15px;
  --options-gap: calc(var(--buttons-size) / 2);
  display: flex;
  font-size: 14px;

  &--options-gap-lg {
    --options-gap: 18px;
  }

  .radio-button {
    --button-size: var(--buttons-size);

    &:not(:last-child) {
      margin-right: var(--options-gap);
    }
  }

  &--column {
    flex-direction: column;

    .radio-button:not(:first-child) {
      margin: 0;
      margin-top: var(--options-gap);
    }
  }
}

.radio-button {
  --button-size: 15px;

  .mat-radio-container {
    width: var(--button-size);
    height: var(--button-size);

    .mat-radio-ripple,
    .mat-ripple-element {
      left: calc(50% - var(--button-size)) !important;
      top: calc(50% - var(--button-size)) !important;
      height: calc(var(--button-size) * 2) !important;
      width: calc(var(--button-size) * 2) !important;
    }
  }

  &:not(.mat-radio-checked) {
    .mat-radio-inner-circle {
      transform: scale(0);
    }
  }

  .mat-radio-outer-circle,
  .mat-radio-inner-circle {
    height: var(--button-size);
    width: var(--button-size);
  }
}

.kp-radio-button-s {
  .mat-radio-label-content {
    font-size: 14px !important;
  }
}
