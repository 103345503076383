.buttons-container {
  display: flex;
  align-self: flex-start;

  &--self-align-center {
    align-self: center;
  }

  &--self-align-end {
    align-self: flex-end;
  }

  &--align-items-start {
    align-items: flex-start;
  }

  &--align-items-center {
    align-items: center;
  }

  &--align-items-end {
    align-items: flex-end;
  }

  &--justify-between-between {
    justify-content: space-between;
  }

  &--full-width {
    width: 100%;
  }

  &--ml-auto {
    margin-left: auto;
  }

  &--mr-auto {
    margin-right: auto;
  }

  button,
  .button {
    &:not(:last-child) {
      margin-right: 9px;
    }
  }
}
