.default-container {
  background-color: var(--bg-white-color);
  --container-y-padding: 15px;
  --container-x-padding: 21px;
  border-radius: 1px;
  display: flex;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 41px;
    max-height: 41px;
    padding: 0 var(--container-x-padding);
    border-bottom: 1px solid var(--thin-graphic-color);
    width: 100%;

    &--no-border {
      border: none;
    }

    &--bg-light-color {
      background-color: var(--bg-light-color);
    }
  }

  &__header-name {
    display: flex;
    align-items: center;

    kp-svg {
      margin-left: 3px;
      color: var(--accent-color);
    }
  }

  &__content {
    display: flex;

    &--column {
      flex-direction: column;
    }
  }

  &__item {
    padding: var(--container-y-padding) var(--container-x-padding);

    &--no-paddings {
      padding: 0;
    }

    &--border-bottom {
      border-bottom: 1px solid var(--thin-graphic-color);
    }
  }

  &--column {
    flex-direction: column;
  }

  &--bg-transparent {
    background-color: transparent;
  }
}
