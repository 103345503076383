.checkbox {
  &.mat-checkbox {
    display: inline-flex;
    height: 14px;
    width: 14px;
  }

  .mat-checkbox-frame {
    // border: 2px solid var(--thin-graphic-color);
    border: 2px solid #a8aeba;
  }

  .mat-checkbox-frame,
  .mat-checkbox-background {
    border-radius: 2px;
  }

  .mat-checkbox-inner-container {
    // border: 2px solid #a8aeba;
    border-radius: 2px;

    height: 14px;
    width: 14px;
  }

  &--inline-block {
    &.mat-checkbox {
      display: inline-block;
    }
  }

  .mat-checkbox-layout .mat-checkbox-label {
    font-size: 12px;
    font-weight: 400;
  }

  .mat-checkbox-ripple.mat-ripple {
    left: calc(50% - 15px);
    top: calc(50% - 15px);
    height: 30px;
    width: 30px;
  }
}
