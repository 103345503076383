.secondary-text {
  color: var(--secondary-color);

  &--sm {
    font-size: 12px;
  }

  &--bold {
    font-weight: 500;
  }
}
