@import "src/assets/styles/ui-kit/common-colors";

.link {
  padding: 3px 4px;
  color: var(--accent-color);
  font-size: 14px;
  border-radius: 2px;
  width: max-content;
  white-space: nowrap;

  &--no-padding {
    padding: 0;
  }

  &--simple {
    color: $primary;
    padding: 0;
    border: none;
    border-radius: 0;
    white-space: normal;
    font-weight: inherit;
    font-size: inherit;

    &:hover {
      text-decoration: underline;
    }
  }
}
