kp-svg {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: min-content;
  width: min-content;
  transition: width 0.3s linear;

  .svg-container {
    display: flex;
    height: inherit;
    width: inherit;
    color: inherit;
    cursor: pointer;
    transition: color 0.1s linear;

    svg {
      height: inherit;
      width: inherit;
    }
  }
}
