@import 'common-colors';

.kp-switcher {
  display: block;

  //#region mat-slide-toggle-bar
  .mat-slide-toggle-bar {
    height: 24px;
    width: 44px;
    border-radius: 2000px;
    background: #e1e6e9;
    box-shadow: 0px 0px 2px 0px #0000001f inset;
  }

  &.mat-checked .mat-slide-toggle-bar {
    background: $primary;
  }

  &.mat-disabled {
    opacity: 1 !important;

    .mat-slide-toggle-bar {
      background: $neutral-disabled;
      box-shadow: none;
    }

    .mat-slide-toggle-thumb {
      box-shadow: none;
    }

    &.mat-checked {
      .mat-slide-toggle-bar {
        background: $primary-disabled;
      }
    }
  }
  //#endregion mat-slide-toggle-bar

  //#region mat-slide-toggle-thumb-container
  .mat-slide-toggle-thumb-container {
    top: 2px;
    left: 2px;
  }

  &.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
    transform: translate3d(19px, 0, 0);
  }
  //#endregion mat-slide-toggle-thumb-container

  //#region mat-slide-toggle-thumb
  .mat-slide-toggle-thumb {
    width: 20px;
    height: 20px;
    box-shadow: 0px 3px 8px 0px #00000026;
  }

  &.mat-checked .mat-slide-toggle-thumb {
    background: white;
  }
  //#endregion mat-slide-toggle-thumb
}
