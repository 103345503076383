.table-wrapper {
  width: 100%;

  kp-table,
  .table-container {
    display: block;
    overflow-x: auto;
    overflow-y: hidden;
  }

  &__paginator-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 14px;
  }

  &__paginator {
    margin-left: auto;

    &--align-left {
      margin-left: 0;
      margin-right: auto;
    }
  }
}

.table {
  --border-style: 1px solid var(--thin-graphic-color);

  table-layout: fixed;
  background-color: var(--bg-white-color);
  border-collapse: collapse;
  width: 100%;

  .table-head__th-content,
  .table-row__td-content {
    padding: 0;
    margin: 0;
  }

  &--ml-first-th-and-td {
    th:first-of-type,
    td:first-of-type {
      padding-left: 12px;
    }
  }
}

.table .table-head {
  border-bottom: var(--border-style);

  &__settins-icon {
    color: var(--icon-black-color);
  }

  &__th-content {
    vertical-align: middle;
    text-align: left;
    height: 40px;
    padding: 0 7px;
    user-select: none;

    color: var(--secondary-color);
    font-weight: 500;
    font-size: 12px;

    &--interactive {
      cursor: pointer;
    }
  }

  &__th-text-and-icon {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    color: inherit;
    font-weight: inherit;
    font-size: inherit;
  }

  &__th-text {
    font-weight: inherit;
    font-size: inherit;
  }

  &__sort-icons {
    display: flex;
    flex-direction: column;
    margin-left: 5px;
  }

  &__sort-icon {
    color: var(--inactive-text-color);

    &:first-child {
      transform: rotate(180deg);
    }

    &--active {
      color: var(--accent-color);
    }
  }
}

.table .table-row {
  border-bottom: var(--border-style);

  &--clickable {
    cursor: pointer;
  }

  &__td-content {
    vertical-align: middle;
    text-align: left;
    height: 72px;
    padding: 0 7px;
    font-weight: 300;
    font-size: 12px;
    color: var(--text-black-color);

    kp-select {
      max-width: -webkit-fill-available;
    }
  }

  &__td-content-item {
    &--link {
      display: inline-block;

      &:hover {
        text-decoration: underline;
      }
    }

    &--action-text {
      height: fit-content;
      width: fit-content;
      border-bottom: 1px solid var(--secondary-color);
      cursor: pointer;
    }
  }
}

.table {
  .table-head__th-content,
  .table-row__td-content {
    &--action {
      text-align: center;
      padding: 0;
      width: 30px;

      > * {
        display: inline-block;
      }
    }
  }
}
