@import 'src/assets/styles/ui-kit/common-colors';

.primary-text-container {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  justify-content: center;

  &--left {
    justify-content: flex-start;
  }
}

.primary-text {
  padding: 3px 4px;
  background-color: var(--thin-graphic-color);
  color: var(--secondary-color);
  text-transform: uppercase;
  font-weight: 500;
  font-size: 10px;
  border-radius: 2px;
  width: max-content;
  white-space: nowrap;
  letter-spacing: 0.03em;
  height: max-content;

  &--files {
    padding: 3px 4px;
    background-color: var(--thin-graphic-color);
    color: var(--secondary-color);
    font-size: 10px;
    border-radius: 2px;
    width: max-content;
    white-space: nowrap;
    letter-spacing: 0.03em;
  }

  &--rounded {
    border-radius: 20px;
  }

  &--gray {
    background-color: var(--bg-gray-color);
  }

  &--dark-gray {
    background-color: #8f95a7;
    color: var(--text-white-color);
  }

  &--yellow {
    background-color: var(--bg-yellow-color);
    color: var(--text-white-color);
  }

  &--accent {
    background-color: var(--accent-color);
    color: var(--text-white-color);
  }

  &--red-gradient {
    background: $primary;
    color: #ffffff;
  }

  &--pastel-pink {
    background-color: #f5a8a8;
    color: var(--text-white-color);
  }

  &--light-blue {
    background-color: #7eabd4;
    color: var(--text-white-color);
  }

  &--turquoise {
    background-color: #40a4b1;
    color: var(--text-white-color);
  }

  &--wet-asphalt {
    background-color: var(--secondary-color);
    color: var(--text-white-color);
  }

  &--raspberry {
    background-color: #a81d61;
    color: var(--text-white-color);
  }
}
