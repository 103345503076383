.button {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  cursor: pointer;
  font-size: 12px;
  line-height: 18px;
  height: 32px;
  padding: 6px 12px;
  border-radius: 2px;
  border: none;
  transition: color, background-color 0.2s linear;
  @extend .button--black;

  &--lg {
    height: 36px;
  }

  &--xl {
    height: 40px;
  }

  kp-svg {
    color: inherit;
    margin-right: 12px;
  }

  &--only-icon {
    kp-svg {
      margin: 0;
    }
  }

  &--icon-right {
    flex-direction: row-reverse;

    kp-svg {
      margin-right: 0;
      margin-left: 12px;
    }
  }

  &--selected {
    @extend .button--white;
  }

  &--black {
    background-color: var(--bg-black-color);
    @extend .button--text-white;
  }

  &--white {
    background-color: var(--bg-white-color);
    border: 1px solid var(--thin-graphic-color);
    @extend .button--text-black;

    &.button--selected {
      @extend .button--black;
    }
  }

  &--accent {
    background-color: var(--accent-color);
    @extend .button--text-white;
  }

  &--transparent {
    border: transparent;
    background-color: transparent;

    &.button--selected {
      @extend .button--white;
      border: none;
    }
  }

  &--simple {
    padding: 0;
    height: max-content;
    width: max-content;
    @extend .button--text-bold;
    @extend .button--transparent;
    @extend .button--text-black;
  }

  &--text-bold {
    font-weight: 500;
  }

  &--text-normal {
    font-weight: 400;
  }

  &--text-white {
    color: var(--text-white-color);
  }

  &--text-black {
    color: var(--text-black-color);
  }

  &--text-secondary {
    color: var(--secondary-color);

    kp-svg {
      color: var(--icon-black-color);
    }
  }

  &--font-md {
    font-size: 14px;
  }

  &--no-border {
    border: none;
  }

  &--justify-start {
    justify-content: flex-start;
  }

  &--no-actions {
    cursor: default;
    pointer-events: none;
  }

  &--with-shadow {
    box-shadow: 0 0 5px 0 rgb(184 184 184);
  }

  &:hover {
    opacity: 0.75;
  }

  &:disabled {
    background-color: var(--bg-inactive-color);
    border: none;
    color: var(--text-white-color);
    cursor: default;

    &:hover {
      opacity: 1;
    }
  }

  .primary-text {
    margin-left: auto;
    height: 15px;
    min-width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
