.quill-editor {
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  height: 100%;
  width: 100%;

  &--border-red {
    .ql-toolbar.ql-snow,
    .ql-container.ql-snow {
      border-color: var(--red-border-color);
    }
  }

  &--no-border {
    .ql-toolbar.ql-snow,
    .ql-container.ql-snow {
      border: none;
    }

    .ql-toolbar.ql-snow {
      border-bottom: 1px solid var(--thin-graphic-color);
    }
  }

  .ql-toolbar.ql-snow {
    background-color: var(--bg-light-color);
  }

  .ql-toolbar.ql-snow,
  .ql-container.ql-snow {
    border-color: var(--thin-graphic-color);
  }
}

.quill-view {
  display: flex;

  .ql-editor {
    padding: 0;
  }
}
