%no-right-border {
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.actions-bar {
  display: flex;
  align-items: center;
  margin: 22px 0;
  width: 100%;

  &__items-controls {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__items-control {
    &--mr-default {
      margin-right: 21px;
    }

    &--ml-auto {
      margin-left: auto;
    }

    &--mr-auto {
      margin-right: auto;
    }

    &--no-right-border {
      @extend %no-right-border;
      .select-bar {
        @extend %no-right-border;
      }
    }
  }

  &__search-field {
    width: 305px;
  }

  &__filter-icon {
    margin-right: 16px;
  }

  &__view-control-icons {
    display: flex;
    margin-left: 15px;
  }

  &__view-control-icon {
    color: var(--inactive-icon-color);

    &:not(:last-child) {
      margin-right: 10px;
    }

    &--active {
      color: var(--icon-black-color);
    }
  }
}
